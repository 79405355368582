import {observable} from 'mobx';
import {
  HOUR_ROW_HEIGHT,
  RESOURCE_PLANNER_BUSINESS_CASE_PADDING,
  RESOURCE_PLANNER_BUTTON_WRAPPER,
  RESOURCE_PLANNER_CONTAINER_BC_CONTAINER_HEIGHT,
  RESOURCE_PLANNER_FOOTER_HEIGHT,
  RESOURCE_PLANNER_HEADER_HEIGHT,
  RESOURCE_PLANNER_INTERVAL_HEADER_HEIGHT
} from '../constants/StyleConstants';
import ViewModeStore from './ViewModeStore';
import {ViewEnum} from '../constants/ViewEnums';

const scrollStore = observable({
  intervalHeaderLeft: 0,
  intervalHeaderRight: 0,
  containerTop: 0,
  fullMode: false,
  fullModeButtonVisible: true,
  filterOpen: false,
  resourcePlannerHeaderPadding: 0,
  filterHeight: 0,
  virtualizedTableWidth: 0,
  virtualizedTableHeight: 0,
  virtualizedTableHeaderHeight: RESOURCE_PLANNER_INTERVAL_HEADER_HEIGHT,

  get resourcePlannerComputedHeight() {
    let resourcePlannerHeight = 0;
    const resourcePlannerContainer = document.querySelector('.resource-planner-container');

    if (resourcePlannerContainer) {
      resourcePlannerHeight = resourcePlannerContainer.getBoundingClientRect().height - RESOURCE_PLANNER_FOOTER_HEIGHT;
    } else {
      if (ViewModeStore.projectID && !scrollStore.fullMode) {
        resourcePlannerHeight = RESOURCE_PLANNER_CONTAINER_BC_CONTAINER_HEIGHT;
      } else {
        let footerBlock = document.querySelector('body>footer');
        let footerBlockHeight = footerBlock ? footerBlock.offsetHeight : 0;
        resourcePlannerHeight = document.body.clientHeight - RESOURCE_PLANNER_HEADER_HEIGHT - this.containerTop - RESOURCE_PLANNER_INTERVAL_HEADER_HEIGHT - footerBlockHeight;
      }
    }
    return resourcePlannerHeight;
  }
});

const changeResize = () => {
  const el = document.querySelector('.node-collection-container'),
    resourcePlannerEl = document.querySelector('.resource-planner');

  if (!el || !resourcePlannerEl) {
    return;
  }

  if (scrollStore.fullMode) {
    scrollStore.resourcePlannerHeaderPadding = resourcePlannerEl.getBoundingClientRect().left;
    scrollStore.intervalHeaderLeft = el.getBoundingClientRect().width + resourcePlannerEl.getBoundingClientRect().left - window.pageXOffset;
  }
  else {
    scrollStore.resourcePlannerHeaderPadding = 0;
    scrollStore.intervalHeaderLeft = 0;
  }
};

const calculateVirtualizedTableWidth = () => {
  const pageContent = document.querySelector('.page-content-width');
  let padding = 0,
    planner = document.querySelector('.resource-planner');

  if (pageContent) {
    const getPadding = parseInt(window.getComputedStyle(pageContent).getPropertyValue('padding-left'));
    padding = !scrollStore.fullMode ? getPadding * 2 : 0;
  }

  if(ViewModeStore.isQuickSilver && !scrollStore.fullMode){
    padding = padding + 50 + (ViewModeStore.isSecondaryNavOpened ? 48 : 0);
  }

  const parentWidth = ViewModeStore.projectID ? (planner && planner.offsetWidth - RESOURCE_PLANNER_BUSINESS_CASE_PADDING) : window.innerWidth;
  if (scrollStore.filterOpen) {
    setTimeout(() => {
      const filter = document.querySelector('.filter');
      if (filter) {
        scrollStore.virtualizedTableWidth = window.innerWidth - filter.clientWidth - padding;
      }
    })
  } else {
    scrollStore.virtualizedTableWidth = parentWidth - padding;
  }
};

const calculateVirtualizedTableHeight = () => {
  const footer = document.querySelector('.new-footer'),
    rpTableBody = document.querySelector('.rp-table-body'),
    planner = document.querySelector('.resource-planner'),
    visualizationBlock = document.querySelector('.visualization-chart');

  if (ViewModeStore.projectID && !scrollStore.fullMode) {
    scrollStore.virtualizedTableHeight = (planner && planner.offsetHeight) - (rpTableBody && rpTableBody.offsetTop);
  } else {
    scrollStore.virtualizedTableHeight = window.innerHeight -
      (rpTableBody && rpTableBody.getBoundingClientRect().top) +
      (visualizationBlock && visualizationBlock.offsetHeight) -
      (footer && footer.offsetHeight) +
      (ViewModeStore.activeView.key === ViewEnum.user ? RESOURCE_PLANNER_INTERVAL_HEADER_HEIGHT - 17 : 23);
  }
};

const getNodeRowsCountInPage = () => {
  /**
   * original class `.resource-planner-header` - header.less
  * */
  const heightOfResourcePlannerHeaderClass = 32;
  const bottomPaddingOfResourcePlannerHeaderClass = 16;

  const rpTableBodyTopPos = document.querySelector('.resource-planner').getBoundingClientRect().top + bottomPaddingOfResourcePlannerHeaderClass + heightOfResourcePlannerHeaderClass,
    footer = document.querySelector('.new-footer'),
    footerHeight = footer ? footer.offsetHeight : 0,
    btnWrapperHeight = ViewModeStore.activeView.key !== ViewEnum.user ? RESOURCE_PLANNER_BUTTON_WRAPPER : 0;

  return parseInt((window.innerHeight - rpTableBodyTopPos - RESOURCE_PLANNER_INTERVAL_HEADER_HEIGHT - footerHeight - btnWrapperHeight) / HOUR_ROW_HEIGHT);
};

export default {
  scrollStore,
  changeResize,
  calculateVirtualizedTableWidth,
  calculateVirtualizedTableHeight,
  getNodeRowsCountInPage
}
